import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { replaceTextWithValues } from "src/common/utils/text-replace";

import { useModel, useCountry, useFetch, useGlobalContent } from "../../hooks";
import { switchCountry } from "../../../facades/website";
import { setOpenOverlay, setCloseOverlay } from "../../../store/actions/app";
import cookieFactory from "../../../cookieFactory";
import { findCountryTranslation } from "./helpers";

import {
  trackSwitchCountryIsVisible,
  trackSwitchCountryClosed,
  trackSwitchCountryStayInCountry,
} from "../../../analytics/category/switch-country";

import Panel from "wonderbly-components/lib/Panel";
import RichText from "../Prismic/RichText";
import CloseButton from "../CloseButton";
import Modal from "../Modal";
import { WonderblyIcon } from "../SVGs";
import Country from "./Country";

import logger from "src/logger";

import "./SwitchCountry.scss";

const SwitchCountry = ({
  title,
  content,
  ctaTitle,
  closeCtaTitle,
  closeCtaContent,
  allCountriesContent,
}) => {
  const dispatch = useDispatch();
  const { prefix: websiteCountryPrefix } = useCountry();
  const { data, loading } = useFetch(switchCountry);
  const userOverrideCookie = cookieFactory("locale_user_override_always", {
    expires: 60,
  });
  const { prismicCountryListModel } = useModel();
  const prismicCountries = prismicCountryListModel.countries;
  const { countryOption } = useGlobalContent("country");

  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    dispatch(shouldRender ? setOpenOverlay(window.scrollY) : setCloseOverlay());
  }, [shouldRender]);

  useEffect(() => {
    if (!loading && data && data.displayComponent) {
      setShouldRender(
        !sessionStorage.getItem("locale_user_override_session") &&
          !userOverrideCookie.get()
      );
    }
  }, [loading]);

  function handleCloseButton() {
    trackSwitchCountryClosed();
    sessionStorage.setItem("locale_user_override_session", true);
    setShouldRender(false);
  }

  function handleStayInCountry() {
    trackSwitchCountryStayInCountry(websiteCountryPrefix);
    userOverrideCookie.set(true);
    setShouldRender(false);
  }

  if (!shouldRender) {
    return null;
  }

  const { iso: userCountryIso } = data.model;
  const { name: userCountryName } =
    countryOption.find(({ code }) => code === userCountryIso) || {};

  if (!userCountryName) {
    logger.warn(
      `Unable to find ${userCountryIso} as an available country option in Prismic.`
    );
    setShouldRender(false);
    return null;
  }

  trackSwitchCountryIsVisible();

  return (
    <Modal isOpen default>
      <Panel className="SwitchCountry__panel">
        <CloseButton
          className="SwitchCountry__close-button"
          handleOnClick={handleCloseButton}
        />
        <Panel.Section className="SwitchCountry__panel__main">
          <RichText
            className="SwitchCountry__panel__content"
            fields={[
              {
                type: "heading1",
                text: replaceTextWithValues(title, {
                  country: userCountryName,
                }),
              },
              {
                type: "paragraph",
                text: replaceTextWithValues(content, {
                  country: `**${userCountryName}**`,
                }),
              },
            ]}
          />
          <div className="SwitchCountry__country-list">
            {data.countries.map(({ model, content }) => {
              const locale = model.contentLocales[0]
                .split("-")[0]
                .toUpperCase();
              const { name: userCountryTranslated } = findCountryTranslation(
                prismicCountries,
                model.contentLocales,
                model.iso
              ) || { name: userCountryName };
              return (
                <Country
                  key={model.prefix}
                  title={replaceTextWithValues(ctaTitle, {
                    country: userCountryTranslated,
                    locale,
                  })}
                  prefix={model.prefix}
                  name={content.name}
                  flag={content.flag}
                />
              );
            })}
            <Country
              title={closeCtaTitle}
              subtitle={replaceTextWithValues(closeCtaContent, {
                country: `**${userCountryName}**`,
              })}
              handleOnClick={handleStayInCountry}
              name={content.name}
              image={<WonderblyIcon />}
            />
          </div>
        </Panel.Section>
        <Panel.Section className="SwitchCountry__panel__footer">
          <RichText
            className="SwitchCountry__link"
            fields={[
              {
                type: "paragraph",
                text: allCountriesContent,
              },
            ]}
          />
        </Panel.Section>
      </Panel>
    </Modal>
  );
};

SwitchCountry.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  ctaTitle: PropTypes.string.isRequired,
  closeCtaTitle: PropTypes.string.isRequired,
  closeCtaContent: PropTypes.string.isRequired,
  allCountriesContent: PropTypes.string.isRequired,
};

export default SwitchCountry;
