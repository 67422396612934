import React from "react";
import PropTypes from "prop-types";

import takeWhile from "lodash/takeWhile";

import Icon from "wonderbly-components/lib/Icon";
import RichText from "../../Prismic/RichText";
import { trackSwitchCountryNavigateToCountry } from "../../../../analytics/category/switch-country";

const Country = ({
  name,
  prefix,
  flag,
  image,
  title,
  subtitle,
  handleOnClick,
}) => {
  const content = takeWhile(
    [
      {
        type: "heading4",
        text: title,
      },
      {
        type: "paragragh",
        text: subtitle,
      },
    ],
    ({ text }) => !!text
  );

  function handleClick() {
    if (handleOnClick) {
      return handleOnClick();
    }
    trackSwitchCountryNavigateToCountry(prefix);
  }

  return (
    <a
      className="SwitchCountry__country"
      key={name}
      href={handleOnClick ? null : `/${prefix}`}
      onClick={handleClick}
    >
      <div className="SwitchCountry__country__flag">
        {flag && !image && (
          <img src={flag.url} alt={flag.alt || name} width="40" />
        )}
        {image && !flag && image}
      </div>
      <div className="SwitchCountry__country__wrapper">
        <RichText
          className="SwitchCountry__country__content"
          fields={content}
        />
      </div>
      <div className="SwitchCountry__country__arrow">
        <Icon name="arrow-right-1" />
      </div>
    </a>
  );
};

Country.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  prefix: PropTypes.string,
  flag: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string,
  }),
  image: PropTypes.node,
  handleOnClick: PropTypes.func.isRequired,
};

export default Country;
