import once from "lodash/once";
import { track } from "../facade";

const dispatch = (action, label = null) =>
  track(action, {
    label,
    category: `Switch Country`,
    value: null,
  });

export const trackSwitchCountryIsVisible = once(() => dispatch("Is visible"));
export const trackSwitchCountryClosed = once(() => dispatch("Closed modal"));
export const trackSwitchCountryStayInCountry = once((currentISO) =>
  dispatch(`Stayed in country with prefix: /${currentISO}`)
);
export const trackSwitchCountryNavigateToCountry = once((selectedISO) =>
  dispatch(`Navigated to country with prefix: /${selectedISO}`)
);
